import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { UiCard } from '@uireact/card';
import { UiViewport, Breakpoints } from '@uireact/foundation';
import { UiText } from '@uireact/text';
import Playground from '../../../src/Playground';
import { UiGrid, UiGridItem } from '../src/';
import * as packageJson from '../package.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uigrid--uigriditem"
    }}>{`UiGrid & UiGridItem`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/grid/card/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Structure and organize the UI using a grid column`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/grid`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <h3 {...{
      "id": "1-using-just-uigrid"
    }}>{`1. Using just UiGrid`}</h3>
    <Playground __position={1} __code={'<UiGrid cols={3} rows={2} colsGap=\"four\" justifyItems=\"center\">\n  <UiCard fullWidth fullHeight>\n    <UiText>Item 1!</UiText>\n  </UiCard>\n  <UiCard fullWidth fullHeight>\n    <UiText>Item 2!</UiText>\n  </UiCard>\n</UiGrid>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiViewport,
      Breakpoints,
      UiText,
      Playground,
      UiGrid,
      UiGridItem,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiGrid cols={3} rows={2} colsGap="four" justifyItems="center" mdxType="UiGrid">
    <UiCard fullWidth fullHeight mdxType="UiCard">
      <UiText mdxType="UiText">Item 1!</UiText>
    </UiCard>
    <UiCard fullWidth fullHeight mdxType="UiCard">
      <UiText mdxType="UiText">Item 2!</UiText>
    </UiCard>
  </UiGrid>
    </Playground>
    <h3 {...{
      "id": "2-using-uigrid-with-uigriditem"
    }}>{`2. Using UiGrid with UiGridItem`}</h3>
    <Playground __position={2} __code={'<UiGrid cols={3} rows={2} colsGap=\"four\">\n  <UiViewport criteria=\"l|xl\">\n    <UiGridItem cols={2}>\n      <UiCard>\n        <UiText>Item 1!</UiText>\n      </UiCard>\n    </UiGridItem>\n  </UiViewport>\n  <UiGridItem placeSelf=\"stretch\" rows={2}>\n    <UiCard>\n      <UiText>Item 2!</UiText>\n      <UiText>Yeah, 2 rows is nice!</UiText>\n    </UiCard>\n  </UiGridItem>\n</UiGrid>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiViewport,
      Breakpoints,
      UiText,
      Playground,
      UiGrid,
      UiGridItem,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiGrid cols={3} rows={2} colsGap="four" mdxType="UiGrid">
    <UiViewport criteria="l|xl" mdxType="UiViewport">
      <UiGridItem cols={2} mdxType="UiGridItem">
        <UiCard mdxType="UiCard">
          <UiText mdxType="UiText">Item 1!</UiText>
        </UiCard>
      </UiGridItem>
    </UiViewport>
    <UiGridItem placeSelf="stretch" rows={2} mdxType="UiGridItem">
      <UiCard mdxType="UiCard">
        <UiText mdxType="UiText">Item 2!</UiText>
        <UiText mdxType="UiText">Yeah, 2 rows is nice!</UiText>
      </UiCard>
    </UiGridItem>
  </UiGrid>
    </Playground>
    <p>{`In this case as we are using the UigridItem we are able to manipulate each grid item on its own.`}</p>
    <p>{`Also notice that in Item 1 we are making use of the `}<a parentName="p" {...{
        "href": "./packages-foundation-docs-viewport"
      }}>{`UiViewport`}</a>{` to render/remove it depending on the breakpoint`}</p>
    <h3 {...{
      "id": "3-using-gridbreakpointsdistribution-prop"
    }}>{`3. Using GridBreakpointsDistribution prop`}</h3>
    <p>{`This prop give you the ability to set a number of cols/rows depending on the breakpoint.`}</p>
    <Playground __position={3} __code={'<UiGrid\n  cols={{ small: 1, medium: 2, large: 3, xlarge: 3 }}\n  rows={2}\n  colsGap={20}\n  rowsGap={10}\n>\n  <UiCard>\n    <UiText>Item 1!</UiText>\n  </UiCard>\n  <UiCard>\n    <UiText>Item 2!</UiText>\n  </UiCard>\n  <UiCard>\n    <UiText>Item 3!</UiText>\n  </UiCard>\n</UiGrid>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiViewport,
      Breakpoints,
      UiText,
      Playground,
      UiGrid,
      UiGridItem,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiGrid cols={{
        small: 1,
        medium: 2,
        large: 3,
        xlarge: 3
      }} rows={2} colsGap={20} rowsGap={10} mdxType="UiGrid">
    <UiCard mdxType="UiCard">
      <UiText mdxType="UiText">Item 1!</UiText>
    </UiCard>
    <UiCard mdxType="UiCard">
      <UiText mdxType="UiText">Item 2!</UiText>
    </UiCard>
    <UiCard mdxType="UiCard">
      <UiText mdxType="UiText">Item 3!</UiText>
    </UiCard>
  </UiGrid>
    </Playground>
    <h3 {...{
      "id": "2-using-custom-uigriditem-col-span"
    }}>{`2. Using custom UiGridItem col span`}</h3>
    <Playground __position={4} __code={'<UiGrid cols={4} colsGap=\"5px\">\n  <UiGridItem cols={2}>\n    <UiCard>\n      <UiText>Item 1!</UiText>\n    </UiCard>\n  </UiGridItem>\n  <UiGridItem placeSelf=\"stretch\" cols={2} startingCol={3} startingRow={2}>\n    <UiCard>\n      <UiText>Item starting on the 3rd col and 2nd row</UiText>\n    </UiCard>\n  </UiGridItem>\n</UiGrid>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiViewport,
      Breakpoints,
      UiText,
      Playground,
      UiGrid,
      UiGridItem,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiGrid cols={4} colsGap="5px" mdxType="UiGrid">
    <UiGridItem cols={2} mdxType="UiGridItem">
      <UiCard mdxType="UiCard">
        <UiText mdxType="UiText">Item 1!</UiText>
      </UiCard>
    </UiGridItem>
    <UiGridItem placeSelf="stretch" cols={2} startingCol={3} startingRow={2} mdxType="UiGridItem">
      <UiCard mdxType="UiCard">
        <UiText mdxType="UiText">Item starting on the 3rd col and 2nd row</UiText>
      </UiCard>
    </UiGridItem>
  </UiGrid>
    </Playground>
    <h2 {...{
      "id": "uigrid-props"
    }}>{`UiGrid Props`}</h2>
    <Props of={UiGrid} mdxType="Props" />
    <h2 {...{
      "id": "uigriditem-props"
    }}>{`UiGridItem Props`}</h2>
    <Props of={UiGridItem} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      